import React from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const WrapFeeBrochurePage = () => {
  const pdfUrl = `/Legal.pdf?v=${new Date().getTime()}`
  return (
    <Layout>
      <SEO title="Legal Notices and Special Disclaimers" />
      <Container>
        <h2 className="text-center font-size-base">
          LEGAL NOTICES AND SPECIAL DISCLAIMERS
        </h2>
        <p>
          Copyright {new Date().getFullYear()} ATM.com, Inc. All rights reserved
          worldwide.
        </p>
        <p>
          ATM.COM<sup>&reg;</sup> and DATA DATING<sup>&reg;</sup> are registered
          trademarks and ANT MONEY<sup>&trade;</sup> is an unregistered, common
          law trademark of ATM.com, Inc. All other trademarks are the property
          of their respective owners. GOOGLE PLAY<sup>&reg;</sup> and the Google
          Play logo are registered trademarks of Google, Inc. APPLE
          <sup>&reg;</sup>, the APPLE logo, and IPHONE<sup>&reg;</sup> are
          registered trademarks of Apple, Inc.
        </p>
        <p>
          1. This website is operated by <strong>ATM.com, Inc.</strong> (“ATM”).
        </p>
        <p>
          ATM IS NOT A BROKER DEALER, WE ARE NOT AN INVESTMENT ADVISOR, AND WE
          DO NOT PURPORT TO AND DO NOT, IN ANY FASHION, PROVIDE INVESTMENT
          ADVICE, TAX, ACCOUNTING, ACTUARIAL, RECORDKEEPING, LEGAL,
          BROKER/DEALER OR ANY RELATED SERVICES OR ADVICE. NO CONTENT,
          MATERIALS, OR INSTRUCTIONS SHOULD BE UNDERSTOOD AS CONSTITUTING A
          RECOMMENDATION TO ENTER IN ANY SECURITIES TRANSACTION. WE HAVE NO
          ACCESS TO NON-PUBLIC INFORMATION ABOUT PUBLICLY TRADED COMPANIES, AND
          WE ARE NOT REGULATED BY THE U.S. SECURITIES AND EXCHANGE COMMISSION.
        </p>
        <p>
          INFORMATION PROVIDED BY ATM CUSTOMER SUPPORT IS FOR INFORMATIONAL AND
          GENERAL EDUCATIONAL PURPOSES ONLY AND IS NOT INVESTMENT OR FINANCIAL
          ADVICE, OR ANY OTHER TYPE OF PROFESSIONAL ADVICE.
        </p>
        <p>
          2. Investment advisory services are provided exclusively by{" "}
          <strong>Ant Money Advisors, LLC</strong> (“AMA”), an SEC-registered
          investment adviser. AMA PROVIDES NO ASSURANCE, REPRESENTATION OR
          PROMISE REGARDING FUTURE EARNINGS OR INCOME, OR THAT YOU WILL MAKE ANY
          SPECIFIC AMOUNT OF MONEY, OR ANY MONEY AT ALL, OR THAT YOU WILL NOT
          LOSE MONEY. PAST PERFORMANCE IS NOT INDICATIVE OF FUTURE RESULTS. YOU
          SHOULD UNDERTAKE YOUR OWN DUE DILIGENCE REGARDING YOUR EVALUATION OF
          ANY SERVICES AND/OR PRODUCTS PRESENTED ON THIS WEBSITE.
        </p>
        <p>
          AMA’s terms and policies can be found at{" "}
          <a href="https://www.antmoneyadvisors.com">
            www.antmoneyadvisors.com
          </a>{" "}
          or through{" "}
          <a href="https://www.adviserinfo.sec.gov">www.adviserinfo.sec.gov</a>{" "}
          and our Form CRS can be found{" "}
          <a href="https://www.antmoneyadvisors.com/form-crs-adv-part-3/">
            here
          </a>
          . AMA facilitates trading through DriveWealth, LLC, an SEC-registered
          broker-dealer and member FINRA and SIPC. Securities in your account
          protected up to $500,000. For details, please see{" "}
          <a href="https://www.sipc.org">https://www.sipc.org</a>. Check the
          background of this firm on FINRA’s BrokerCheck.
        </p>
        <hr />
        <p>
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            Click here
          </a>{" "}
          to download the Legal Notices and Special Disclaimers.
        </p>
      </Container>
    </Layout>
  )
}

export default WrapFeeBrochurePage
